

// grid

$width: 96%;
$gutter: 4%;
$breakpoint-small: 540px;
$breakpoint-med: 720px;
$breakpoint-large: 960px;

$footer-height: 80px;

.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: $width;
}

.col-1-sm { width:calc($width / 12) - calc($gutter * 11 / 12); }
.col-2-sm { width: calc($width / 6) - calc($gutter * 10 / 12); }
.col-3-sm { width: calc($width / 4) - calc($gutter * 9 / 12); }
.col-4-sm { width: calc($width / 3) - calc($gutter * 8 / 12); }
.col-5-sm { width: calc($width / (12 / 5)) - calc($gutter * 7 / 12); }
.col-6-sm { width: calc($width / 2) - calc($gutter * 6 / 12); }
.col-7-sm { width: calc($width / (12 / 7)) - calc($gutter * 5 / 12); }
.col-8-sm { width: calc($width / (12 / 8)) - calc($gutter * 4 / 12); }
.col-9-sm { width: calc($width / (12 / 9)) - calc($gutter * 3 / 12); }
.col-10-sm { width: calc($width / (12 / 10)) - calc($gutter * 2 / 12); }
.col-11-sm { width: calc($width / (12 / 11)) - calc($gutter * 1 / 12); }
.col-12-sm { width: $width; }

@media only screen and (min-width: $breakpoint-med) {
  .col-1 { width:calc($width / 12) - calc($gutter * 11 / 12); }
  .col-2 { width: calc($width / 6) - calc($gutter * 10 / 12); }
  .col-3 { width: calc($width / 4) - calc($gutter * 9 / 12); }
  .col-4 { width: calc($width / 3) - calc($gutter * 8 / 12); }
  .col-5 { width: calc($width / (12 / 5)) - calc($gutter * 7 / 12); }
  .col-6 { width: calc($width / 2) - calc($gutter * 6 / 12); }
  .col-7 { width: calc($width / (12 / 7)) - calc($gutter * 5 / 12); }
  .col-8 { width: calc($width / (12 / 8)) - calc($gutter * 4 / 12); }
  .col-9 { width: calc($width / (12 / 9)) - calc($gutter * 3 / 12); }
  .col-10 { width: calc($width / (12 / 10)) - calc($gutter * 2 / 12); }
  .col-11 { width: calc($width / (12 / 11)) - calc($gutter * 1 / 12); }
  .col-12 { width: $width; }

  .hidden-sm {
    display: block;
  }
}

.pushdown {
  padding-top: 20px;
}

.pushdown2 {
  padding-top: 40px;
}

.pushdown3 {
  padding-top: 60px;
}

/**
 * Site header
 */
.site-header {

  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 900;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: calc($spacing-unit / 2);
    background-color: $background-color;
    border: 1px solid $grey-color;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

/**
 * Site footer
 */

footer {

  margin-top: auto;

  @include relative-font-size(0.85);
  color: $grey-color;
  @extend %clearfix;

  width: 100%;

  .wrapper {
    padding-top: 20px;
  }
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

 */**
 * Page content
 */
main {
  padding: $spacing-unit 0;
  flex-grow: 1;
  display: flex;
}

.page-heading {
  @include relative-header-size(1);
}

.post-list-heading {
  @include relative-header-size(.5);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



strong {

  font-weight: 400;
  background-color: $red-color;
  padding-left: 5px;
  padding-right: 5px;

  &.red {
    background-color: $red-color;
  }

  &.orange {
    background-color: $orange-color;
  }

  &.yellow {
    color: #333;
    background-color: $yellow-color;
  }

  &.blue {
    background-color: $blue-color;
  }

  &.green {
    color: #333;
    background-color: $green-color;
  }

  &.pink {
    background-color: $pink-color;
  }

  &.purple {
    background-color: $purple-color;
  }

  a {
    color: $text-color;
    text-decoration: none;

    &:visited {
      color: $text-color;
    }

    &:hover {
      color: $text-color;
      text-decoration: underline;
    }
  }

}

 h1 {
    @include relative-header-size(1);

    @include media-query($on-laptop) {
      @include relative-header-size(1 * .8);
    }
  }

  h2 {
    @include relative-header-size(.75);

    @include media-query($on-laptop) {
      @include relative-header-size(.75 * .8);
    }
  }

  h3 {
    @include relative-header-size(.5);

    @include media-query($on-laptop) {
      @include relative-header-size(.5 * .8);
    }
  }

  h4 {
    @include relative-header-size(.4);

    @include media-query($on-laptop) {
      @include relative-header-size(.4 * .8);
    }
  }

h1, h2, h3, h4 {

  &.red {
    color: $red-color;
  }

  &.orange {
    color: $orange-color;
  }

  &.yellow {
    color: $yellow-color;
  }

  &.blue {
    color: $blue-color;
  }

  &.green {
    color: $green-color;
  }

  &.pink {
    color: $pink-color;
  }

  &.purple {
    color: $purple-color;
  }

  &.red-gradient {
    @include header-gradient($red-color);
  }

  &.orange-gradient {
    @include header-gradient($orange-color);
  }

  &.yellow-gradient {
    @include header-gradient($yellow-color);
  }

  &.blue-gradient {
    @include header-gradient($blue-color);
  }

  &.green-gradient {
    @include header-gradient($green-color);
  }

  &.pink-gradient {
    @include header-gradient($pink-color);
  }

  &.purple-gradient {
    @include header-gradient($purple-color);
  }

}

code, pre {
  background-color: #000;
  border: 1px solid #674870;
  corner-radius: 3px;
}

.highlight {
  
}

