@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$base-header-font-family: "TT Firs Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !default;
$base-header-font-weight: 900;

$spacing-unit:     30px !default;

$text-color:       #eee !default;
$background-color: #191126 !default;

$link-color:      #4A67FC !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$red-color:        #EE3D3D !default;
$orange-color:     #EF6700 !default;
$yellow-color:     #DDE016 !default;
$blue-color:       #4A67FC !default;
$green-color:      #02d609 !default;
$pink-color:       #FF4EED !default;
$purple-color:     #A934F0 !default;

$base-header-size:   80px !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    960px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@mixin relative-header-size($ratio) {
  font-size: $base-header-size * $ratio;
  font-family: $base-header-font-family;
  font-weight: $base-header-font-weight;
  line-height: 1.1em;
  color: $yellow-color;
  padding-bottom: $base-header-size * $ratio * .1;
}

@mixin header-gradient($color){
  color: $color;
    background: linear-gradient(180deg, lighten($color, 10%) 1.31%, darken($color, 10%) 103.36%);
    background-clip: text;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.06);
}

// Import partials.
@import
  "divergent/base",
  "divergent/layout",
  "divergent/syntax-highlighting"
;
